
    // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
    @import "src/styles/variables.scss";
  
.bullet {
  display: inline-block;
  height: 14px;
  width: 14px;
  margin-right: $space-xs;
  background-color: $color-accent-fresh;
  border-radius: 50%;
  border: 1px solid $color-accent-italian;
  box-shadow: $border-inner-new;

  &.full {
    background-color: $color-explorer-primary;
  }

  @include media-breakpoint-up(lg) {
    height: 16px;
    width: 16px;
    border: 1px solid $color-accent-italian;
    box-shadow: $border-inner-new;
  }
}
