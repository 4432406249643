
    // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
    @import "src/styles/variables.scss";
  
.Navigation {
  :global(.mantine-NavLink-root) {
    height: 40px;
    border-radius: $border-radius;

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }

    &:last-of-type {
      margin-top: 80px;
    }
  }
}
