
    // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
    @import "src/styles/variables.scss";
  
.overlayContainer {
  background: $color-accent-italian30;
  display: block;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;

  .spinner {
    height: 2.7rem;
    left: calc(50% - 2.7rem / 2);
    line-height: 3rem;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    text-align: center;
    top: calc(50% - 2.7rem / 2);
    width: 2.7rem;

    .spinnerIcon {
      color: $color-text-negative;
    }
  }
}
