
    // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
    @import "src/styles/variables.scss";
  
.container {
  box-shadow: 0 0 0.25em 0.25em rgb(0 0 0 / 15%);
  background-color: $color-background-main;
  color: $color-text;
  display: none;
  position: fixed;
  right: 0;
  text-align: left;
  text-transform: none;
  top: $navbar-height-desktop;
  width: 520px;
  z-index: 5;

  &.active {
    display: block;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

.itemCountInfoContainer {
  box-shadow: 0px 20px 10px -10px $color-accent-fresh;
}

.itemContainer {
  max-height: 434px;
  overflow-y: auto;
}

.priceBoxWrapper {
  padding: 0 $space-sm;
}

.ctaContainer {
  .ctaButton {
    display: flex;
  }

  svg {
    margin-left: $space-xs;
  }
}

.perksContainer {
  padding: $space-xs $space-sm;
}
