
    // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
    @import "src/styles/variables.scss";
  
.area {
  --product-list-modal-height: var(--modal-content-max-height, calc(100dvh - var(--modal-y-offset) * 2));
  --product-list-modal-padding: var(--mb-padding, var(--mantine-spacing-md));

  height: calc(100dvh - (var(--product-list-modal-padding) * 2) - 167px);

  @include media-breakpoint-up(md) {
    height: calc(var(--product-list-modal-height) - (var(--product-list-modal-padding) * 2) - 167px);
  }
}

.control {
  :global(.mantine-SegmentedControl-control) {
    --sc-font-size: var(--mantine-font-size-sm);

    &[data-active] {
      label {
        font-weight: bold;
      }
    }
  }
}
