
    // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
    @import "src/styles/variables.scss";
  
.table {
  border-collapse: collapse;
  text-align: left;
  width: 100%;
}

.header,
.data {
  font-size: $font-x-sub;
  line-height: 21px;
  vertical-align: middle;
}

.data {
  width: 100%;
}

.dataFlex {
  display: flex;
  justify-content: space-between;
}

.rowFlex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: $space-xs 0;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

.bulletsWrapper {
  display: flex;
  align-items: center;
}

.header {
  width: 10rem;
  margin: 0;
  font-weight: 600;

  @include media-breakpoint-up(md) {
    margin: 0 $space-sm 0 0;
  }
}

.bioHeader {
  line-height: 0;
}

.bioLogo {
  height: 40px;
  max-width: 60px;
}

.infoIcon {
  float: right;
}
