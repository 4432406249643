
    // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
    @import "src/styles/variables.scss";
  
.target {
  z-index: 201;
}

.option {
  padding: 12px;

  .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
