
    // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
    @import "src/styles/variables.scss";
  
.cardWrapper {
  list-style-type: none;
  flex: 1 0 calc(50% + 1px);
  max-width: calc(50% + 1px);
  margin-bottom: -1px;
  margin-right: -1px;
  align-self: stretch;

  &.isInsideCarousel {
    max-width: 375px;
    height: 100%;
  }

  @include media-breakpoint-up(md) {
    flex: 1 0 calc(33.33% + 1px);
    max-width: calc(33.33% + 1px);
    min-width: 260px;
  }

  @include media-breakpoint-up(lg) {
    flex: 1 0 calc(25% - $space-sm * 3 / 4); // we have 3 margins and 4 elements
    max-width: calc(25% - $space-sm * 3 / 4);
  }
}

.subscriptionCardWrapper {
  list-style-type: none;
  flex: 1 0 calc(50% - $space-sm / 2);
  max-width: calc(50% - $space-sm / 2);
  align-self: stretch;

  // Magic breakpoints because the cards are inside the modal.
  @media (min-width: 935px) and (max-width: 1240px) {
    flex: 1 0 calc(33.33% - $space-sm * 2 / 3); // we have 2 margins and 3 elements
    max-width: calc(33.33% - $space-sm * 2 / 3);
    min-width: 260px;
  }

  @media (min-width: 1241px) {
    flex: 1 0 calc(25% - $space-sm * 3 / 4); // we have 3 margins and 4 elements
    max-width: calc(25% - $space-sm * 3 / 4);
  }
}

.cardContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  color: $color-background-dark !important; // keep the original color on dark columns
  background-color: $color-accent-fresh;
  text-align: center;
  position: relative;
  cursor: pointer;
  border: $border-light;

  @include media-breakpoint-up(lg) {
    border: $border-light;
    border-radius: $border-radius-large;
    overflow: hidden;
    transition: all 200ms ease;

    &:hover {
      box-shadow: $box-shadow-card-hover;
      border-color: $color-accent-drying;
    }
  }
}

.cardHeadline {
  margin-top: $space-xs;
  padding: 0 $space-sm;
  overflow-wrap: break-word;
}

.info {
  display: inline-block;
  padding: 0 $space-sm;
  margin: $space-xs 0;
}

.ratingWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.roastDegreeContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding-top: $space-md;

  .textWrapper {
    position: absolute;
    top: -25px;
    left: 2px;
  }

  .roastDegreeWrapper {
    scale: 0.7;
    padding: 0 0 $space-xs;

    @include media-breakpoint-up(sm) {
      scale: 0.93;
      padding: 0 0 $space-sm;
    }

    @include media-breakpoint-up(md) {
      scale: 1;
      padding: 0 0 $space-sm;
    }
  }

  // hide below 350 we support only from 375 anyway
  @media (max-width: 350px) {
    display: none;
  }
}
