
    // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
    @import "src/styles/variables.scss";
  
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.roastLevelBase {
  height: 14px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 2px;
  width: 225px;

  &.centered {
    justify-content: center;
  }

  @include media-breakpoint-up(lg) {
    height: 17px;
  }
}

%roastChipBase {
  width: 32%;
  height: 14px;
  border: 1px solid $color-accent-italian;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  box-shadow: $border-inner-new;

  @include media-breakpoint-up(lg) {
    height: 17px;
  }
}

.roastChip01 {
  @extend %roastChipBase;
  background-color: rgba(0, 0, 0, 0.1);
}
.roastChip02 {
  @extend %roastChipBase;
  background-color: rgba(0, 0, 0, 0.2);
}
.roastChip03 {
  @extend %roastChipBase;
  background-color: rgba(0, 0, 0, 0.3);
}

.pill {
  position: absolute;
  margin-left: -17px;
  width: 10px;
  height: 24px;
  background-color: $color-explorer-primary;
  border: 1px solid $color-accent-italian;
  border-radius: 100px;
  box-shadow: $border-inner-new;

  &.centered {
    margin-left: -16px;
  }

  @include media-breakpoint-up(lg) {
    width: 12px;
    margin-left: -20px;
    height: 28px;
    border-width: 1px;
    top: -5px;

    &.centered {
      margin-left: -18px;
    }
  }
}

.infoIcon {
  float: right;
}
